import { FC } from 'react'
import styled from 'styled-components'
import { PERIOD_DISPLAY, Period } from '@dextoroprotocol/sdk/constants'

import { setSelectedPortfolioTimeframe } from 'state/futures/reducer'
import { selectSelectedPortfolioTimeframe } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'

const TIMEFRAMES: Period[] = [Period.ONE_DAY, Period.ONE_WEEK, Period.ONE_MONTH, Period.ONE_YEAR]

const refractedPeriod = (timeframe: Period) =>
	timeframe === Period.ONE_DAY
		? '24H'
		: timeframe === Period.ONE_WEEK
		? '7D'
		: timeframe === Period.ONE_MONTH
		? '30D'
		: timeframe === Period.THREE_MONTHS
		? '3M'
		: timeframe === Period.ONE_YEAR
		? '12M'
		: timeframe

export const Timeframe: FC = () => {
	const dispatch = useAppDispatch()
	const selectedTimeframe = useAppSelector(selectSelectedPortfolioTimeframe)

	return (
		<Container>
			{TIMEFRAMES.map((timeframe) => (
				<StyledBtn
					key={PERIOD_DISPLAY[timeframe]}
					onClick={() => dispatch(setSelectedPortfolioTimeframe(timeframe))}
					isActive={selectedTimeframe === timeframe}
				>
					{refractedPeriod(timeframe)}
				</StyledBtn>
			))}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding: 2px 12px 2px 6px;
	gap: 6px;
	background-color: transparent;
`

const StyledBtn = styled.button<{ isActive: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 28px;
	width: auto;
	height: 28px;
	font-size: 12px;
	padding: 0 12px;
	line-height: 16px;
	user-select: none;
	border-radius: 8px;
	white-space: nowrap;
	letter-spacing: 0.1ch;
	text-transform: uppercase;
	pointer-events: auto;
	touch-action: auto;
	border: none;
	background-color: ${(props) =>
		props.isActive ? props.theme.colors.selectedTheme.button.fillHover : 'transparent'};
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.primary
			: props.theme.colors.selectedTheme.gray};
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;

	&:hover {
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.lightWhite};
	}
	,
	&:active {
		cursor: pointer;
	}

	&:before {
		content: unset;
	}
`
