const SmartWalletIcon = (prop: { color: string }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="22px" height="22px">
			<g
				fill={prop.color}
				fill-rule="nonzero"
				stroke="none"
				stroke-width="1"
				stroke-linecap="butt"
				stroke-linejoin="miter"
				stroke-miterlimit="10"
				stroke-dasharray=""
				stroke-dashoffset="0"
				font-family="none"
				font-weight="none"
				font-size="none"
				text-anchor="none"
			>
				<g transform="scale(8.53333,8.53333)">
					<path d="M14,3.05078c-5.828,0.482 -10.46722,5.12122 -10.94922,10.94922h10.94922zM16,3.05078v11.53516l8.1582,8.1582c1.769,-2.091 2.8418,-4.79014 2.8418,-7.74414c0,-6.29 -4.842,-11.43922 -11,-11.94922zM3.05078,16c0.51,6.158 5.65922,11 11.94922,11c2.954,0 5.65314,-1.0728 7.74414,-2.8418l-8.1582,-8.1582z"></path>
				</g>
			</g>
		</svg>
	)
}

export default SmartWalletIcon
