import { ETH_ADDRESS, ETH_COINGECKO_ADDRESS, ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { SynthSymbol } from '@dextoroprotocol/sdk/data'
import { FuturesMarginType } from '@dextoroprotocol/sdk/types'
import { formatDollars, toWei } from '@dextoroprotocol/sdk/utils'
import Wei from '@synthetixio/wei'
import { FC, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { ExchangeTokens } from 'types/synths'
import styled from 'styled-components'

import Logo from 'assets/svg/brand/dextoro-logo.svg'
import LogoLight from 'assets/svg/brand/dextoro-logo-light.svg'
import Connector from 'containers/Connector'
import PortfolioChart from 'sections/dashboard/PortfolioChart'
import { TabPanel } from 'components/Tab'
import FuturesPositionsTable from 'sections/dashboard/FuturesPositionsTable'
import SynthBalancesTable from 'sections/dashboard/SynthBalancesTable'

import { selectBalances } from 'state/balances/selectors'
import { fetchTokenList } from 'state/exchange/actions'
import { setFuturesAccountType } from 'state/futures/reducer'
import { selectFuturesPortfolio, selectMarkPrices } from 'state/futures/selectors'
import { selectCurrentTheme } from 'state/preferences/selectors'
import {
	selectActiveSmartMarginPositionsCount,
	selectSmartMarginActivePositions,
} from 'state/futures/smartMargin/selectors'
import { useAppDispatch, useAppSelector, useFetchAction } from 'state/hooks'
import sdk from 'state/sdk'
import { selectSynthsMap } from 'state/wallet/selectors'
import logError from 'utils/logError'
import ROUTES from 'constants/routes'
import useIsL2 from 'hooks/useIsL2'
import { selectCrossMarginActivePositions } from 'state/futures/crossMargin/selectors'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { getSynthDescription } from 'utils/futures'
import { Body } from 'components/Text'

export enum PositionsTab {
	SMART_MARGIN = 'smart margin',
	SPOT = 'spot',
}

const Portfolio: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const currentTheme = useAppSelector(selectCurrentTheme)
	const { walletAddress } = Connector.useContainer()
	const isL2 = useIsL2()
	const balances = useAppSelector(selectBalances)
	const portfolio = useAppSelector(selectFuturesPortfolio)
	const smartPositionsCount = useAppSelector(selectActiveSmartMarginPositionsCount)

	const [activePositionsTab, setActivePositionsTab] = useState<PositionsTab>(
		PositionsTab.SMART_MARGIN
	)

	const { network } = Connector.useContainer()
	const synthsMap = useAppSelector(selectSynthsMap)

	const { tokenBalances } = useAppSelector(({ balances }) => balances)
	// Only available on Optimism mainnet
	const oneInchEnabled = network.id === 10

	const [exchangeTokens, setExchangeTokens] = useState<ExchangeTokens>([])

	useFetchAction(fetchTokenList, { dependencies: [network] })

	const DextoroLogo = useMemo(
		() => (currentTheme === 'light' ? <DextoroLightLogo /> : <DextoroDarkLogo />),
		[currentTheme]
	)

	useEffect(() => {
		const initExchangeTokens = async () => {
			try {
				const exchangeBalances = oneInchEnabled
					? Object.values(tokenBalances)
							.filter((token) => !synthsMap[token.token.symbol as SynthSymbol])
							.map((value) => ({
								name: value.token.name,
								currencyKey: value.token.symbol,
								balance: toWei(value.balance),
								address:
									value.token.address === ETH_ADDRESS ? ETH_COINGECKO_ADDRESS : value.token.address,
							}))
					: []

				const tokenAddresses = oneInchEnabled
					? [...Object.values(tokenBalances).map((value) => value.token.address.toLowerCase())]
					: []

				const coinGeckoPrices = await sdk.exchange.batchGetCoingeckoPrices(tokenAddresses, true)

				const _exchangeTokens = exchangeBalances.map((exchangeToken) => {
					const { name, currencyKey, balance, address } = exchangeToken

					const price = coinGeckoPrices
						? toWei(coinGeckoPrices[address]?.usd?.toString())
						: ZERO_WEI
					const priceChange = coinGeckoPrices
						? toWei(coinGeckoPrices[address]?.usd_24h_change?.toString()).div(100)
						: ZERO_WEI

					const usdBalance = balance.mul(price)

					return {
						synth: currencyKey as SynthSymbol,
						description: name,
						balance,
						usdBalance,
						price,
						priceChange,
					}
				})

				setExchangeTokens(_exchangeTokens)
			} catch (e) {
				logError(e)
			}
		}

		;(async () => {
			await initExchangeTokens()
		})()
	}, [oneInchEnabled, synthsMap, tokenBalances])

	const POSITIONS_TABS = useMemo(() => {
		const exchangeTokenBalances = exchangeTokens.reduce(
			(initial: Wei, { usdBalance }: { usdBalance: Wei }) => initial.add(usdBalance),
			ZERO_WEI
		)
		return [
			{
				name: PositionsTab.SMART_MARGIN,
				label: t('dashboard.overview.positions-tabs.smart-margin'),
				badge: smartPositionsCount,
				active: activePositionsTab === PositionsTab.SMART_MARGIN,
				detail: formatDollars(portfolio.smartMargin),
				disabled: false,
				onClick: () => {
					setActivePositionsTab(PositionsTab.SMART_MARGIN)
					dispatch(setFuturesAccountType(FuturesMarginType.SMART_MARGIN))
				},
			},
			{
				name: PositionsTab.SPOT,
				label: t('dashboard.overview.positions-tabs.spot'),
				active: activePositionsTab === PositionsTab.SPOT,
				detail: formatDollars(balances.totalUSDBalance.add(exchangeTokenBalances)),
				disabled: false,
				onClick: () => setActivePositionsTab(PositionsTab.SPOT),
			},
		]
	}, [
		t,
		dispatch,
		smartPositionsCount,
		exchangeTokens,
		balances.totalUSDBalance,
		activePositionsTab,
		portfolio.smartMargin,
		setActivePositionsTab,
	])
	const crossMarginPositions = useAppSelector(selectCrossMarginActivePositions)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)
	let data = useMemo(() => {
		const positions = FuturesMarginType.SMART_MARGIN ? smartMarginPositions : crossMarginPositions
		return positions.map((position) => {
			const description = getSynthDescription(position.market.asset, t)
			const priceInfo = pricesInfo[position.market.asset]
			const marketPrice = markPrices[position.market.marketKey] ?? ZERO_WEI
			return {
				...position,
				description,
				marketPrice,
				priceInfo,
			}
		})
	}, [crossMarginPositions, markPrices, pricesInfo, smartMarginPositions, t])
	const showTable = useMemo(() => (data.length > 0 ? true : false), [data])
	const showExchangeTable = useMemo(
		() => (exchangeTokens.length > 0 ? true : false),
		[exchangeTokens.length]
	)

	return (
		<>
			<HeadingContainer href={ROUTES.Home.MainSite}>{DextoroLogo}</HeadingContainer>
			<PortfolioChart POSITIONS_TABS={POSITIONS_TABS} showExchangeTable={showExchangeTable} />

			{showTable && (
				<TabPanel name={PositionsTab.SMART_MARGIN} activeTab={activePositionsTab}>
					<FuturesPositionsTable accountType={FuturesMarginType.SMART_MARGIN} />
				</TabPanel>
			)}
			{showExchangeTable && (
				<TabPanel name={PositionsTab.SPOT} activeTab={activePositionsTab}>
					<SynthBalancesTable exchangeTokens={exchangeTokens} />
				</TabPanel>
			)}
			{!showTable && !showExchangeTable && walletAddress && (
				<>
					<HeadingStyleOpenPostion size="large">{'Open Positions'}</HeadingStyleOpenPostion>
					{!isL2 ? (
						<OpenMarketDiv>
							{t('dashboard.overview.futures-positions-table.wrong-network')}
						</OpenMarketDiv>
					) : (
						<OpenMarketDiv>{t('futures.market.trade.button.no-open-postion-para')}</OpenMarketDiv>
					)}
				</>
			)}
		</>
	)
}

const HeadingContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 24px;
	top: 0;
	z-index: 1;
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	box-shadow: 0 12px 12px ${(props) => props.theme.colors.selectedTheme.background};
`

const DextoroDarkLogo = styled(Logo)`
	width: 200px;
	height: 20px;
	margin-left: -40px;
`

const DextoroLightLogo = styled(LogoLight)`
	width: 200px;
	height: 20px;
	margin-left: -40px;
`

const OpenMarketDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 18px;
	padding: 16px 24px;
	min-height: 68px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	background-color: ${(props) => props.theme.colors.selectedTheme.table.fill};
`

const HeadingStyleOpenPostion = styled(Body)`
	font-size: 24px;
	line-height: 30px;
	margin-top: 30px;
	margin-bottom: 20px;
	white-space: nowrap;
	overflow: auto;
	padding-left: 18px;
`

export default Portfolio
